<template>
  <div class="container">
  <el-form class="demo-form-inline">
    <el-form-item label="应用名称">
    <el-input v-model="name" :placeholder="info.name"></el-input>
  </el-form-item>
  <el-form-item label="平台">
    <el-input v-model="platform" :placeholder="info.platform"></el-input>
  </el-form-item>
  <el-form-item label="文件名">
    <el-input v-model="filename"></el-input>
  </el-form-item>
  <el-form-item label="版本">
    <el-input v-model="version" :placeholder="info.version"></el-input>
  </el-form-item>
  <div class="">填写文件名，上传安装包。文件地址： {{ fileUrl }}</div>
    <el-upload
      class="upload-demo"
      :action="domain"
      :show-file-list="false"
      :on-success="UploadFaceImg"
      :data="QiNiYunL"
      :before-upload="before"
      multiple
      drag
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
    </el-upload>

    <el-form-item label="更新类型">
      <el-radio v-model="updateType" :label="1">apk更新</el-radio>
      <el-radio v-model="updateType" :label="2">wgt更新</el-radio>
      <el-radio v-model="updateType" :label="3">强制更新</el-radio>
    </el-form-item>
  <el-form-item label="更新内容">
    <el-input
  type="textarea"
  :rows="5"
  placeholder="请输入内容"
  v-model="content">
</el-input>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="updateAppVersion">更新</el-button>
  </el-form-item>
</el-form>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      content:'',
      version:'',
      img: "",
      fileUrl:'',
      filename:'',
      platform:'',
      name:'',
      updateType:1,
      id:this.$route.query.id,
      domain: "http://up-z0.qiniup.com/",
      upload_qiniu_addr: "https://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: "",
      },
      info:{}
    };
  },

  created() {
    this.getAppList();
  },
  watch: {
    clear(newV, oldV) {
      if (newV) {
        // console.log(newV);
        this.img = "";
      }
    },
  },
  methods: {
    getAppList() {
      this.$axios({
        method: "get",
        url: "message/systemVersion/queryListPage",
        params:{
          id:this.id
        }
      }).then((res) => {
        this.info=res.data.data[0]
        this.version = this.info.version
        this.fileUrl= this.info.url
        this.name= this.info.name
        this.platform= this.info.platform
        this.filename= this.info.url.split('https://img.curiousmore.com/')[1] 
        this.updateType = this.info.type
      });
    },
    updateAppVersion() {
      this.$axios({
        method: "post",
        url: "message/systemVersion/update",
        data: {
          id:this.id,
          iteration_content: this.content,
          name: this.name,
          platform: this.platform,
          url: this.fileUrl,
          version: this.version,
          type: this.updateType,
        },
      }).then((res) => {
        this.$message({message:res.data.message})
      });
    },
    reset() {
      this.img = "";
    },
    UploadFaceImg(file) {
      this.fileUrl = this.upload_qiniu_addr + file.key;
      this.$message({message:'上传成功'})
      console.log(this.img);
    },
    async before() {
      if(!this.filename){
        this.$message({message:'请输入文件名'})
        return false
      }else{
        let res = await this.gettoken();
         return res;
      }
      
    },
    gettoken() {
      return new Promise((resolve, reject) => {
        let _this = this;
        let data = {
          key: this.filename,
          type: "pic",
        };
        _this
          .$axios({
            method: "post",
            url: "https://www.curiousmore.com/qmore/push/upload/getToken",
            data: data,
          })
          .then((res) => {
            _this.QiNiYunL.token = res.data.data;
            _this.QiNiYunL.key = data.key;
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            reject(false);
          });
      });
    },
  },
};
</script>
  
  <style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.el-upload-list__item,
.el-upload-list--picture-card,
.el-upload-list__item-actions,
.el-upload--picture-card,
.avatar-uploader-icon {
  font-size: 14px !important;
  color: #8c939d;
  width: 5.6rem !important;
  height: 5.6rem !important;
  line-height: 5.6rem !important;
  text-align: center;
}
.avatar {
  width: 5.6rem !important;
  height: 5.6rem !important;
  display: block;
  /* margin-right:0px; */
}
</style>
  